/* global Common,sessionStorage,Const,Env,AmazonCognitoIdentity */
/**
 * @file 画面共通処理（ユーザー情報、セッション情報）
 * @author Ozaki Takao
 */
(function () {
  'use select';
  if (typeof window.Common === 'undefined') {
    window.Common = {};
  }
  /**
   * Cognitoユーザー情報の取得
   *
   * @access public
   * @param {string} userName ユーザー名称
   * @returns {object} Cognitoユーザー情報
   */
  Common.getCognitoUser = function (userName) {
    var userData = Common.getUserPoolData(userName);
    return new AmazonCognitoIdentity.CognitoUser(userData);
  };
  /**
   * ユーザープール情報の取得
   *
   * @access public
   * @param {string} userName ユーザー名称
   * @returns {object} Cognitoユーザープール情報
   */
  Common.getUserPoolData = function (userName) {
    var userPool = Common.getUserPool();
    return {
      'Username': userName,
      'Pool': userPool,
      'Storage': sessionStorage
    };
  };
  /**
   * Cognitoユーザープール情報の取得
   *
   * @access public
   * @returns {object} Cognitoユーザープール情報
   */
  Common.getUserPool = function () {
    var poolData = {
      'UserPoolId': Env.COGNITO_USER_POOL_ID,
      'ClientId': Env.COGNITO_CLIENT_ID,
      'Storage': sessionStorage
    };
    return new AmazonCognitoIdentity.CognitoUserPool(poolData);
  };
  /**
   * Cognitoユーザーセッション情報の取得
   *
   * @access public
   * @returns {object} Cognitoユーザープール情報
   */
  Common.getUserSession = function () {
    var pool = Common.getUserPool();
    var cognitoUser = Common.getCognitoUser(pool.getCurrentUser().username);
    return new Promise(function (resolve, reject) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          reject(err);
        }
        resolve(session);
      });
    });
  };
  /**
   * Cognitoユーザーセッション情報の更新
   *
   * @access public
   * @returns {object} Cognitoユーザープール情報
   */
  Common.refreshUserSession = function () {
    var pool = Common.getUserPool();
    var cognitoUser = Common.getCognitoUser(pool.getCurrentUser().username);
    return new Promise(function (resolve, reject) {
      cognitoUser.refreshSession({getToken: function() {return Common.getCachedRefreshToken()}}, function (err, session) {
        if (err) {
          reject(err);
        }
        resolve(session);
      });
    });
  };
  /**
   * CognitoIDトークンの取得
   * @access public
   * @returns {string} CognitoIDトークン
   */
  Common.getIdToken = function () {
    return Common.refreshUserSession().then(function (session) {
      return session.getIdToken().getJwtToken();
    }).catch(function () {
      throw new Error('user session is null');
    });
  };
  /**
   * キャッシュからCognitoIDトークンを取得する
   * @access public
   * @returns {string} CognitoIDトークン
   */
  Common.getCachedIdToken = function () {
    var PREFIX = 'CognitoIdentityServiceProvider.';
    var userId = Common.getUserInfo(Const.SESSION_USER_INFO.USER_ID);
    var key = PREFIX + Env.COGNITO_CLIENT_ID + '.' + userId + '.idToken';
    var idToken = Common.getValueFromSession(key);
    return idToken;
  };
  /**
   * キャッシュからCognitoリフレッシュトークンを取得する
   * @access public
   * @returns {string} CognitoIDトークン
   */
  Common.getCachedRefreshToken = function () {
    var PREFIX = 'CognitoIdentityServiceProvider.';
    var userId = Common.getUserInfo(Const.SESSION_USER_INFO.USER_ID);
    var key = PREFIX + Env.COGNITO_CLIENT_ID + '.' + userId + '.refreshToken';
    var idToken = Common.getValueFromSession(key);
    return idToken;
  };
  /**
   * sessionStorage からすべての情報を消去する
   *
   * @access public
   */
  Common.removeSessionAll = function () {
    sessionStorage.clear();
  };
  /**
   * ログアウト
   *
   * @access public
   */
  Common.logout = function () {
    var pool = Common.getUserPool();
    if (pool.getCurrentUser() !== null) {
      Common.getCognitoUser(pool.getCurrentUser().username).signOut();
    }
    Common.removeSessionAll();
    window.location.href = '/login/login.html';
  };
  // 認証失敗時のポップアップメッセージを多重に表示しないため
  var showedUnauthorizedMsg = false;
  /**
   * 認証失敗時の画面操作
   *
   * @access public
   * @param {array} detailMsgs 詳細メッセージ
   */
  Common.ctrlWhenUnauthorized = function (detailMsgs) {
    if (showedUnauthorizedMsg === false) {
      if (Array.isArray(detailMsgs) && detailMsgs.length > 0) {
        Common.popupCompleteMessage(Common.MSG.COMMON_ERROR_045, detailMsgs);
      } else {
        Common.popupCompleteMessage(Common.MSG.COMMON_ERROR_021);
      }
      showedUnauthorizedMsg = true;
    }
    Common.logout();
  };
  /**
   * ログイン状態を判定
   *
   * @access public
   */
  Common.checkLoginStatus = function () {
    // ユーザー情報を保持していない場合、ログアウト
    var sessUserInfo = sessionStorage.getItem(Const.SESSION_KEY.USER_INFO);
    if (sessUserInfo === null) {
      Common.ctrlWhenUnauthorized();
      throw new Error(Common.MSG.COMMON_ERROR_021);
    }

    // セッション情報を取得できない場合、ログアウト
    Common.getUserSession().catch(function () {
      Common.ctrlWhenUnauthorized();
      throw new Error(Common.MSG.COMMON_ERROR_021);
    });
  };
  /**
   * セッションストレージから値を取得
   *
   * @access public
   * @param {string} requestKey 値に対応するキー
   * @returns {*} 値
   */
  Common.getValueFromSession = function (requestKey) {
    if (sessionStorage === undefined ||
        sessionStorage.getItem(requestKey) === null ||
        sessionStorage.getItem(requestKey) === undefined) {
      return null;
    }
    return sessionStorage.getItem(requestKey);
  };
  /**
   * セッションストレージに値を設定
   *
   * @access public
   * @param {string} requestKey 値に対応するキー
   * @param {*} data 値
   */
  Common.setValueForSession = function (requestKey, data) {
    sessionStorage.setItem(requestKey, data);
  };
  /**
   * セッションストレージから値を削除
   *
   * @access public
   * @param {string} requestKey 値に対応するキー
   */
  Common.removeSession = function (requestKey) {
    if (sessionStorage !== undefined &&
        sessionStorage.getItem(requestKey) !== null &&
        sessionStorage.getItem(requestKey) !== undefined) {
      sessionStorage.removeItem(requestKey);
    }
  };
  /**
   * [session]ユーザー情報から指定した項目の値を取得する
   *
   * @access public
   * @param {string} key 項目キー
   * @returns {string} 項目キーに対応した値(存在しなければundefined)
   */
  Common.getUserInfo = function (key) {
    var userInfoJson = Common.getValueFromSession(Const.SESSION_KEY.USER_INFO);
    if (!userInfoJson) {
      return undefined;
    }
    try {
      var userInfo = JSON.parse(userInfoJson);
      return userInfo[key];
    } catch (e) {
      console.error(e); // parse失敗
      return undefined;
    }
  };
  /**
   * [session]現在ユーザーが、指定のユーザー種別のいずれかに一致するか判定する
   *
   * @access public
   * @param {array} ユーザー種別の配列
   * @returns {boolean} 指定ユーザーのいずれかに一致する場合、true
   */
  Common.matchSomeUserType = function (userTypes) {
    var sessUserType = Common.getUserInfo(Const.SESSION_USER_INFO.USER_TYPE);
    if (!sessUserType) {
      return false;
    }
    return Array.isArray(userTypes) && userTypes.some(function (userType) {
      return String(sessUserType) === String(userType);
    });
  };
  /**
   * [session]現在ユーザーが、指定のユーザー種別のいずれかに一致するか判定する
   *
   * @access public
   * @param {array} ユーザー種別の配列
   * @returns {boolean} 指定ユーザーのいずれかに一致する場合、true
   */
  Common.checkUserType = function (userTypes) {
    if (Array.isArray(userTypes) === false || Common.matchSomeUserType(userTypes) === false) {
      Common.popupCompleteMessage(Common.MSG.COMMON_ERROR_019);
      Common.logout();
      throw new Error(Common.MSG.COMMON_ERROR_019);
    }
  };
  /**
   * 端末のグループ情報をセッションに保存する
   * @access private
   * @param {object} 
   */
  Common.setUserGroupTerminalForSession = function (data) {
    var userGroup = {
      userGroupId: data.user_group_id,
      userGroupName: data.user_group_name
    };
    // 端末のユーザー情報をセッションに保存
    Common.setValueForSession(Const.SESSION_KEY.USER_GROUP_TEREMIANL, JSON.stringify(userGroup));
  };
  /**
   * [session]端末のユーザグループ情報から指定した項目の値を取得する
   *
   * @access public
   * @param {string} key 項目キー
   * @returns {string} 項目キーに対応した値(存在しなければundefined)
   */
  Common.getUserGroupTerminal = function (key) {
    var userGroupJson = Common.getValueFromSession(Const.SESSION_KEY.USER_GROUP_TEREMIANL);
    if (!userGroupJson) {
      return undefined;
    }
    try {
      var userGroup = JSON.parse(userGroupJson);
      return userGroup[key];
    } catch (e) {
      console.error(e); // parse失敗
      return undefined;
    }
  };
  /**
   * 端末とログインユーザーのユーザグループを判定
   *
   * @access public
   */
  Common.checkUserGroup = function () {
    var userGroupId = Common.getUserInfo(Const.SESSION_USER_INFO.USER_GROUP_ID);
    if (userGroupId === undefined || userGroupId === null) {
      // 異常
      return false;
    }
    var userGroupIdTerminal = Common.getUserGroupTerminal('userGroupId');
     if (userGroupIdTerminal === undefined || userGroupIdTerminal === null) {
      // 端末のユーザグループ未設定は正常
      return true;
    }
    // ユーザグループの判定
    if (userGroupId === userGroupIdTerminal) {
      return true;
    }
    return false;
  };
  /**
   * 個別端末情報画面の前画面情報をセッションに保存する
   * @access private
   * @param {string} prevPage 前画面情報 
   */
  Common.setTerminalDetailPrevPageForSession = function (prevPage) {
    // 個別端末情報画面の前画面情報をセッションに保存
    Common.setValueForSession(Const.SESSION_KEY.TERMINAL_DETAIL_PREV_PAGE, prevPage);
  };
  /**
   * [session]個別端末情報画面の前画面情報を取得する
   *
   * @access public
   * @returns {string} 前画面情報(存在しなければundefined)
   */
  Common.getTerminalDetailPrevPage = function () {
    var prevPage = Common.getValueFromSession(Const.SESSION_KEY.TERMINAL_DETAIL_PREV_PAGE);
    if (!prevPage) {
      return undefined;
    }
    return prevPage;
  };
}());
